import React, { useState, useEffect } from 'react';
import Testimonials from "../components/testimonials"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from '../components/layout'

export default function Home({data}) {

  const heroImg = data.images.edges[Math.floor(Math.random() * data.images.edges.length)].node.publicURL;
  const [hero, setHero] = useState(heroImg)

  useEffect(() => {
    const heroImg = data.images.edges[Math.floor(Math.random() * data.images.edges.length)].node.publicURL;
    setHero(heroImg)
  }, [data.images.edges])

  return <Layout>

        <Helmet>
            <title>Freelance Web Developer - Kevin Choppin</title>
            <meta name="description" content="A full-stack web developer working with; PHP, MySQL, HTML, CSS and JS. I have over 18 years of professional experience in building apps of all shapes and sizes, using all kinds of frameworks and tools. Such as; Laravel, Vue.js, React and SCSS." />
        </Helmet>

        <section className="hero container">
            <div className="hero__content">
                <h1>Web Developer and other stuff!</h1>
                <p>Hi, I’m Kev. A full-stack web developer working with; PHP, MySQL, HTML, CSS and JS. I have over { new Date().getFullYear() - 2004 } years of professional experience in building apps of all shapes and sizes, using all kinds of frameworks and tools. Such as; Laravel, Vue.js, React and SCSS.</p>
                <p>I also have a fair amount of experience in DevOps and SysAdmin. Keeping workflows lean with CI/CD and setting up web servers with the likes of; Docker, AWS, NGINX and Apache. I like to (occasionally) <Link to="/blog">blog</Link> about the more interesting parts of my work, <a href="https://twitter.com/kevchoppin" target="_blank" rel="nofollow noopener noreferrer">follow me on twitter</a> for updates of when I do.</p>
            </div>
            <img src={ hero } alt="" />
        </section>

        <Testimonials />
</Layout>
}

export const query = graphql`
  query {

      images: allFile(filter: {relativeDirectory: {eq: "portfolio"}, extension: {eq: "png"}}) {
        edges {
          node {
            publicURL
          }
        }
      }      
  }`