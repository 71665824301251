import React from "react"

class Testimonials extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          currentTestimonial: 0, 
          lastTestimonial: 0, 
          fading: false
        };

        this.testimonials = [
            {
                author: 'Gavin Masters',
                quote: 'Kevin is a talented and experienced php developer whose infectious enthusiasm for any project in which he was involved helped motivate and lift the whole development team.',
                img: 'avatars/gavin-masters.jpg',
                job_title: 'Industry Principal - Magnus'
            },
            {
                author: 'Adie Flute',
                quote: 'Kev is without a doubt one of the best developers it has ever been my pleasure to work with.',
                img: 'avatars/adie-flute.jpg',
                job_title: 'Traditional & Digital Marketer - Alliance'
            },
            {
                author: 'Nic Jones',
                quote: 'Kevin will always find a way to solve a problem, without compromising the quality of the finished project or the code behind.',
                img: 'avatars/nic-jones.jpg',
                job_title: 'Head of eCommerce - Roar Ambition'
            },
            {
                author: 'Richard Ecclestone',
                quote: 'I hugely appreciated Kevin\'s work on my photography website. He took a bespoke design concept, realised it and made it function perfectly.',
                img: 'avatars/richard-ecclestone.jpg',
                job_title: 'Photographer'
            },
            {
                author: 'James Anderson',
                quote: 'Kev has worked on a number of projects for me and each time I’ve been completely delighted with both the process and the results.',
                img: 'avatars/james-anderson.jpg',
                job_title: 'Entrepreneur'
            }
        ];

    }
  
    componentDidMount() {
        this.fader = setInterval( () => {
            this.setState({ fading: true });
            this.fade()
        }, 8000);
    }
  
    componentWillUnmount() {
        clearInterval(this.fader);
    }

    fade() {
        const lastTestimonial = this.state.currentTestimonial;
        const currentTestimonial = lastTestimonial + 1 >= this.testimonials.length ? 0 : lastTestimonial + 1;
        this.setState({ lastTestimonial, currentTestimonial });
    }

    render() {
      return (
        <aside className="testimonials">
            {this.testimonials.map((testimonial, i) => (
                <div key={i} className={`testimonials__item ${this.state.currentTestimonial === i ? '_fade-in' : this.state.lastTestimonial === i && this.state.fading ? '_fade-out' : ''}`}>
                    <blockquote><p>{ testimonial.quote }</p></blockquote>
                    <cite><img src={ testimonial.img } alt="" /> <div className="testimonials__item__cite">{ testimonial.author }<br /><span>{ testimonial.job_title }</span></div></cite>
                </div>
            ))}
        </aside>
      );
    }
  }

  export default Testimonials;